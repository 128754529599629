import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {InventoryRequestGridComponent} from './InventoryRequestGrid/InventoryRequestGrid.component';
import {AuthService} from '../authentication/auth.service';
import {LocategridComponent} from '../locates/locategrid/locategrid.component';
import {InventoryRequest} from './InventoryRequestGrid/InventoryRequest';
import {User} from '../authentication/User/user';
import {BulkImportDialogComponent} from '../shared/dialog/bulk-Import-dialog.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManagementComponent implements OnInit {
  private user;
  private currentUser;
  public inventoryRequests: FormGroup;
  @ViewChild('inventoryrequestgrid', {static: false}) inventoryrequestgrid: InventoryRequestGridComponent;
  @ViewChild('symbolInput', {static: false}) security: ElementRef;
  @ViewChild('csvInput', {static: false}) csvInput: ElementRef;
  private groupId;

  constructor(private afAuth: AngularFireAuth, private fb: FormBuilder, private authService: AuthService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.inventoryRequests = this.fb.group({
      trader: [null, Validators.compose(null)],
      security: [null, Validators.compose(null)],
      quantity: [100, Validators.compose(null)],
      groupId: [null, Validators.compose(null)]
    });
    if (this.afAuth.auth.currentUser != null) {
      this.user = this.afAuth.auth.currentUser;
      this.currentUser = new User(JSON.parse(localStorage.getItem('User')));
    }
    if (this.currentUser.MPIDs.length === 1) {
      this.groupId = this.currentUser.MPIDs[0];
    }
    if (this.currentUser.MPIDs.length === 1) {
      this.groupId = this.currentUser.MPIDs[0];
    }
  }

  csvInputChange(fileInputEvent: any) {
    const file = fileInputEvent.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = this.onFileLoad;
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onloadend = (e) => {
      const textFromFileLoaded = fileReader.result;
      const csvSeparator = ';';
      let txt;
      if (typeof textFromFileLoaded === 'string') {
        txt = textFromFileLoaded.trim().replace(/(^[\t]*\n)/gm, '');
      }
      const csv = [];
      let cols: string[];
      const lines = txt.split('\r');
      for (let i = 1; i < lines.length; i++) {
        cols = lines[i].split(csvSeparator);
        csv.push(cols);
      }
      this.addInventoryRequestList(csv);
    };
  }

  onFileLoad(fileLoadedEvent) {
  }

  openBulkDialog() {
    const dialogRef = this.dialog.open(BulkImportDialogComponent,
      {
        height: 'auto',
        width: 'auto',
        minHeight: '100px',
        maxWidth: '500px',
        panelClass: 'custom-dialog',
        data: {
          groupCodes: this.currentUser.MPIDs
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.groupId = result;
        this.csvInput.nativeElement.click();
      }
    });
  }

  addInventoryRequest() {
    this.inventoryrequestgrid.addInventoryRequest(this.groupId, this.inventoryRequests.get('security').value.toUpperCase(),
      this.inventoryRequests.get('quantity').value);
    this.inventoryRequests.reset();
    this.inventoryRequests.markAsPristine();
    this.clearAllValidationErrors();
    this.inventoryRequests.get('quantity').setValue(100);
    this.security.nativeElement.focus();
  }

  public addInventoryRequestList(list) {
    const user: User = JSON.parse(localStorage.getItem('User'));
    this.inventoryrequestgrid.rows = [];
    const inventoryRequestList: InventoryRequest[] = [];
    list.forEach(item => {
      const request = item.toString().split(',');
      const ir: InventoryRequest = new InventoryRequest({
        groupId: this.groupId,
        security: request[0].trim(),
        quantity: Number(request[1])
      });
      inventoryRequestList.push(ir);
    });
    this.inventoryrequestgrid.addBulkInventoryRequest(inventoryRequestList);
  }

  removeItems() {
    this.inventoryrequestgrid.removeSelectedRows();
  }

  clearAllValidationErrors() {
    for (const name in this.inventoryRequests.controls) {
      this.inventoryRequests.controls[name].setErrors(null);
    }
  }
}
