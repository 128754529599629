import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocatesComponent} from './locates.component';
import {FlexLayoutModule} from '@angular/flex-layout';

import {LocategridComponent} from './locategrid/locategrid.component';
import {AgGridModule} from 'ag-grid-angular';
import {
  MatButtonToggleModule,
  MatCardModule,
  MatDividerModule,
  MatExpansionModule, MatFormFieldModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {LocateStreamService} from './stream/locate-stream.service';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material-module';
import {WatchlistComponent} from '../watchlist/watchlist.component';
import {BulkImportDialogComponent} from '../shared/dialog/bulk-Import-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatCardModule,
        MatIconModule,
        AgGridModule.withComponents([]),
        MatButtonToggleModule,
        MatToolbarModule,
        MatButtonModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDividerModule,
        ReactiveFormsModule,
        MaterialModule,
        FormsModule
    ],
  providers: [LocateStreamService],
  exports: [MatCardModule, MatIconModule],
  entryComponents: [BulkImportDialogComponent],
  declarations: [LocatesComponent,
    LocategridComponent, WatchlistComponent, BulkImportDialogComponent
  ]
})
export class LocatesModule {
}
