import {Component, OnInit} from '@angular/core';
import {WatchSymbol} from './watchSymbol/watchSymbol';
import {AuthService} from '../authentication/auth.service';
import {HttpClient} from '@angular/common/http';
import {User} from '../authentication/User/user';
import {Quote} from '../locates/quote';
import * as firebase from 'firebase';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.scss']
})
export class WatchlistComponent implements OnInit {

  public watchedSymbols: WatchSymbol[];

  constructor(private authService: AuthService,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.watchedSymbols = [];
  }

  private RemoveSymbolFromWatchlist(wSymbol: WatchSymbol) {
    const index: number = this.watchedSymbols.indexOf(wSymbol);
    if (index !== -1) {
      this.watchedSymbols.splice(index, 1);
    }
  }

  public AddSymbolToWatchlist(params) {
    const symbol = params.Symbol.toUpperCase();
    let found = false;
    for (const item of this.watchedSymbols) {
      if (item.Symbol === symbol) {
        found = true;
        break;
      }
    }

    const user: User = JSON.parse(localStorage.getItem('User'));
    const param = {
      ticket: user.Ticket,
      intention: 'request',
      params: {
        Symbol: symbol
      }
    };

    this.httpClient.put<Quote>(environment.locateApiEndpoint + 'quotes',
      param).subscribe(
      (val) => {
        const wSymbol = new WatchSymbol({Symbol: val.Symbol, Price: val.OfferPx, isAvailable: val.QuoteStatus > 0});
        this.watchedSymbols.unshift(wSymbol);
        console.log('POST call successful value returned in body',
          val);
      },
      response => {
        console.log('POST call in error', response);
      },
      () => {
        console.log('The POST observable is now completed.');
      });
  }

  public GetQuote(params): Promise<WatchSymbol> {
    const self = this;
    return new Promise(function (resolve, reject) {
      const symbol = params.Symbol.toUpperCase();
      const user: User = JSON.parse(localStorage.getItem('User'));
      const quote = {
        idUser: user.idUser,
        Trader: user.Handle,
        Symbol: symbol,
        MPID: user.MPIDs[0]

      };
      self.httpClient.post(environment.locateApiEndpoint + 'locate/quote',
        quote, {
          headers: {
            Authorization: `Bearer ${JSON.parse(JSON.stringify(self.authService.afAuth.auth.currentUser)).stsTokenManager.accessToken}`,
            'Content-Type': 'application/json'
          }
        }).subscribe(
        (val) => {
          const quote = JSON.parse(JSON.stringify(val));
          const wSymbol = new WatchSymbol({Symbol: quote.Symbol, Price: quote.OfferPx, isAvailable: quote.QuoteStatus > 0});
          resolve(wSymbol);
        },
        response => {
          reject('');
        },
        () => {
        });
    });
  }

  get showWatchlist(): string {
    return this.watchedSymbols.length > 0 ? 'block' : 'none';
  }
}
