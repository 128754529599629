import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {VpsOrder} from '../vpsOrder';

@Injectable({
  providedIn: 'root'
})
export class LocateStreamService {
  public order$: Observable<VpsOrder[]>;
  private fdb: AngularFireDatabase;
  public collectionData: VpsOrder[] = [];

  /**
   * Whenever collectionData changes, emits the array.
   */
  currentCollectionArray: BehaviorSubject<any> = new BehaviorSubject([]);

  /**
   *  collectionChanged will emit just the events changed
   */
  onCollectionChanged: BehaviorSubject<VpsOrder[]> = new BehaviorSubject([]);

  /**
   * collectionAdded will emit an array of new objects added.
   */
  onCollectionAdded: BehaviorSubject<VpsOrder[]> = new BehaviorSubject([]);

  /**
   * collectionRemoved will emit the elements that were removed
   */
  onCollectionRemoved: BehaviorSubject<VpsOrder[]> = new BehaviorSubject([]);

  /**
   * private observers/subscriptions on the collection
   */

  private collectionAddedObs: Observable<any>;
  private collectionChangedObs: Observable<any>;
  private collectionRemovedObs: Observable<any>;

  private collectionAddedSub: Subscription;
  private collectionChangedSub: Subscription;
  private collectionRemovedSub: Subscription;

  constructor(fdb: AngularFireDatabase) {
    this.fdb = fdb;
  }

  public subscribeManager() {
    this.order$ = this.fdb.list<VpsOrder>('vps-orders')
      .valueChanges()
      .pipe(
        map(changes => {
            return changes.map(rawdata => {
              return new VpsOrder(rawdata);
            });
          }
        )
      );
  }

  public subscribeByMpids(mpids: string[]) {
    this.order$ = this.fdb.list<VpsOrder>('vps-orders')
      .valueChanges()
      .pipe(
        map(changes => {
            return changes.map(rawdata => {
              const order: VpsOrder = new VpsOrder(rawdata);
              return order;
            }).filter(item => mpids.indexOf(item.groupId) > -1);
          }
        )
      );

    // // this.collectionAddedObs = this.fdb.list<VpsOrder>('vps-orders').stateChanges(['child_added'])
    // this.collectionAddedSub = this.fdb.list<VpsOrder>('vps-orders').stateChanges(['child_added']).subscribe(item => {
    //   const collectionArr = [];
    //   const data = item.payload.val();
    //   const order: VpsOrder = new VpsOrder(data);
    //   this.collectionData.push(order);
    //   collectionArr.push(order);
    //   this.onCollectionAdded.next(collectionArr);
    //   this.currentCollectionArray.next(this.collectionData);
    // });
  }

  public subscribeTraderID(traderId: string) {
    this.order$ = this.fdb.list<VpsOrder>('vps-orders',
      ref =>
        ref.orderByChild('Trader').equalTo(traderId))
      .valueChanges()
      .pipe(
        map(changes => {
            return changes.map(rawdata => {
              return new VpsOrder(rawdata);
            });
          }
        )
      );
  }
}


/*
https://github.com/angular/angularfire2/blob/master/docs/firestore/querying-collections.md
.query(ref => ref.where('size', '==', 'large'))

{
query: {
  equalTo: this.route.params.map((params: Params) => params['weekid'])
}
*/
