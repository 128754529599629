import {Routes} from '@angular/router';
import {FullComponent} from './layouts/full/full.component';
import {LocatesComponent} from './locates/locates.component';
import {AuthGuard} from './authentication/auth.guard';
import {AppBlankComponent} from './layouts/blank/blank.component';
import {AngularFireAuthGuard} from '@angular/fire/auth-guard';
import {UserprofileComponent} from './userprofile/userprofile.component';
import {ManagementComponent} from './management/management.component';


export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },

  // {
  //   path: 'login', component: AppBlankComponent,
  //   loadChildren: './authentication/auth.module#AuthModule'
  // },
  {
    path: 'login/:reason',
    component: AppBlankComponent,
    loadChildren: './authentication/auth.module#AuthModule'
  },
  {
    path: '', component: FullComponent,
    canActivate: [AngularFireAuthGuard],
    children: [
      {
        path: 'locates',
        component: LocatesComponent
      },
      {
        path: 'userprofile',
        component: UserprofileComponent
      },
      {
        path: 'management',
        component: ManagementComponent
      }
    ]
    // loadChildren: './locates/locates.module#LocatesModule'

    // {
    //   path: 'locates',
    //   loadChildren:  () => import('./locates/locates.module').then(mod => mod.LocatesModule),
    //   // './locates/locates.module#LocatesModule',
    //   data: { title: 'Locates' }
  }
];
