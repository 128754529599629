// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCakoErjIWozeK0LVpxpj4lShnzhELODu4',
    authDomain: 'vcl-locate-prod.firebaseapp.com',
    databaseURL: '"https://vcl-locate-prod.firebaseio.com',
    projectId: 'vcl-locate-prod',
    storageBucket: 'vcl-locate-prod.appspot.com',
    messagingSenderId: '192032996537',
    appId: '1:192032996537:web:211dba68a601002490af68'
  },
  locateApiEndpoint: 'https://vps-dot-vcl-locate-prod.appspot.com/_ah/api/vps/v1/'
};

//
// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: 'AIzaSyDxze9J7vYRzvwfkkJt2BOD5NGNJ_Q8Ssc',
//     authDomain: 'vc-locate.firebaseapp.com',
//     databaseURL: 'https://vc-locate.firebaseio.com',
//     projectId: 'vc-locate',
//     storageBucket: 'vc-locate.appspot.com',
//     messagingSenderId: '703456509775',
//     appId: '1:703456509775:web:a26d4d0b59ed3f6e834f3a'
//   },
//   locateApiEndpoint: 'https://vps-dot-vc-locate.appspot.com/_ah/api/vps/v1/'
// };

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: 'AIzaSyCNcLM90-rF6YvV8NlbRzoApkdIzGqAN3o',
//     authDomain: 'vc-beta-01.firebaseapp.com',
//     databaseURL: 'https://vc-beta-01.firebaseio.com',
//     projectId: 'vc-beta-01',
//     storageBucket: 'vc-beta-01.appspot.com',
//     messagingSenderId: '391950087962',
//     appId: '1:391950087962:web:939ed6b9a3099591a99ea3'
//   },
//   locateApiEndpoint: 'https://vps-dot-vc-beta-01.appspot.com/_ah/api/vps/v1/'
// };
