import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ManagementComponent} from './management.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AgGridModule} from 'ag-grid-angular';
import {
  MatButtonModule,
  MatCardModule,
  MatDividerModule, MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {InventoryRequestGridComponent} from './InventoryRequestGrid/InventoryRequestGrid.component';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    MatIconModule,
    AgGridModule.withComponents([]),
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTabsModule,
    MatDividerModule,
    MatExpansionModule
  ],
  providers: [],
  exports: [MatCardModule, MatIconModule],
  declarations: [ManagementComponent, InventoryRequestGridComponent]
})

export class ManagementModule {
}
