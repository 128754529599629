export class InventoryRequest {
  public idRequest: number;
  public groupId: string;
  public security: string;
  public quantity: number;

  constructor(data: any) {
    this.idRequest = data.idRequest;
    this.groupId = data.groupId;
    this.security = data.security;
    this.quantity = data.quantity;
  }

}
