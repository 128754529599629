import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {Order} from '../order/order';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {InventoryRequest} from '../../management/InventoryRequestGrid/InventoryRequest';

@Injectable({
  providedIn: 'root'
})
export class InventoryRequestStreamService {
  public inventoryRequests$: Observable<InventoryRequest[]>;
  private fdb: AngularFireDatabase;

  constructor(fdb: AngularFireDatabase) {
    this.fdb = fdb;
  }

  public subscribeByMpids(mpids: string[]) {
    this.inventoryRequests$ = this.fdb.list<InventoryRequest>('vpsInventoryRequests')
      .valueChanges()
      .pipe(
        map(changes => {
            return changes.map(rawdata => {
              const sod = new InventoryRequest(rawdata);
              return sod;
            }).filter(item => mpids.indexOf(item.groupId) > -1);
          }
        )
      );
  }
}

//
//   public subscribeTraderID(traderId: string) {
//     this.order$ = this.fdb.list('orders',
//       ref =>
//         ref.orderByChild('Trader').equalTo(traderId))
//       .valueChanges()
//       .pipe(
//         map(changes => {
//             return changes.map(rawdata => {
//               return new Order(rawdata);
//             });
//           }
//         )
//       );
//   }
// }


/*
https://github.com/angular/angularfire2/blob/master/docs/firestore/querying-collections.md
.query(ref => ref.where('size', '==', 'large'))

{
query: {
  equalTo: this.route.params.map((params: Params) => params['weekid'])
}
*/
