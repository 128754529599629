import 'ag-grid-community';
import {Component, Inject, Injectable, OnDestroy, OnInit} from '@angular/core';
import {LocateStreamService} from '../stream/locate-stream.service';
import {GridOptions} from 'ag-grid-community';
import {CellFormatter} from '../../shared/grid/cell-formatter';
import {Subscription} from 'rxjs';
import {Utils} from '../../shared/utils';
import {User} from '../../authentication/User/user';
import {AuthService} from '../../authentication/auth.service';
import {VpsOrder} from '../vpsOrder';

@Component({
  selector: 'app-locategrid',
  templateUrl: './locategrid.component.html',
  styleUrls: ['./locategrid.component.scss']

})

export class LocategridComponent implements OnInit, OnDestroy {
  public gridApi;
  private gridColumnApi;
  public gridOptions: GridOptions;
  private _sub: Subscription;
  public hasRowsSelected = false;
  public currentUser: User;
  public isManager;

  constructor(private locateStreamService: LocateStreamService) {
    this.currentUser = new User(JSON.parse(localStorage.getItem('User')));
    this.isManager = this.currentUser.isManager();
    this.defineColumns();
    this.defineGridOptions();
  }

  getContextMenuItems(params) {
    const result = [
      'copy',
      // 'csvExport'
    ];
    return result;
  }

  // Grid Events
  gridOnReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.gridApi) {
      this._sub = this.locateStreamService.order$.subscribe(data => {
        // console.log(item);
        // this.gridApi.updateRowData({add: item});
        this.gridApi.setRowData(data);
      });
      this.gridApi.sizeColumnsToFit();
    }
  }

  gridSizeChanged(params) {
    this.gridApi.sizeColumnsToFit();
    // Hack to hide horizontal scroll bar
    const colState = this.gridColumnApi.getColumnState();
    this.gridColumnApi.setColumnWidth(colState[colState.length - 1].colId, colState[colState.length - 1].width - 20);
  }

  public onExportButton() {
    const exportFileName = Utils.createFileName('locates-', new Date(), '', '');
    const params = {
      fileName: exportFileName
    };
    // this.gridApi.exportDataAsExcel(params);
    this.gridApi.exportDataAsCsv(params);
  }

  public SetFilter(value: string) {
    const ordTypeFilled = this.gridApi.getFilterInstance('Status');
    if (value === '') {
      ordTypeFilled.setModel({});
    } else {
      ordTypeFilled.setModel({
        filterType: 'set',
        //type: 'equals',
        values: [value],
        newRowsAction: 'keep'
      });
    }
    this.gridApi.onFilterChanged();
  }

  public getSelectedRows(): any {
    return this.gridApi.getSelectedRows();
  }

  public applyManagerSettings() {
  }

  gridOnCellMouseOut(event) {
  }

  gridOnCellMouseOver(event) {
  }

  gridOnRowSelected(event): boolean {
    this.hasRowsSelected = this.gridApi.getSelectedRows().length > 0;
    return this.hasRowsSelected;
  }

  // component events
  ngOnInit() {
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  private defineGridOptions() {
    const columns: any = this.defineColumns();
    this.gridOptions = {};
    this.gridOptions.defaultColDef = this.defineDefaultColumns();
    this.gridOptions.columnDefs = columns;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.groupIncludeTotalFooter = false;
    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.suppressScrollOnNewData = true;
    this.gridOptions.suppressRowClickSelection = true;
    this.gridOptions.enableRangeSelection = true;


    // function assignments
    this.gridOptions.getRowNodeId = (params) => this.getRowNodeId(params);
    this.gridOptions.getRowClass = (params) => {
      return params.data.statusStyle;
    };
    // this.gridOptions.getRowClass = (params) => CellFormatter.statusStyle(params);
    this.gridOptions.onGridReady = (event) => this.gridOnReady(event);
    this.gridOptions.getContextMenuItems = (event) => this.getContextMenuItems(event);
    this.gridOptions.onCellMouseOver = (event) => this.gridOnCellMouseOver(event);
    this.gridOptions.onCellMouseOut = (event) => this.gridOnCellMouseOut(event);
    // this.gridOptions.isRowSelectable = function (rowData) {
    //   return rowData.data ? (rowData.data.locateStatus === 5 || rowData.data.locateStatus === 0) && rowData.data.idSource === 0 : false;
    // };
    this.gridOptions.isRowSelectable = function (rowData) {
      return rowData.data.isSelectable;
    };
    this.gridOptions.onRowSelected = (event) => this.gridOnRowSelected(event);
  }

  private getRowNodeId(data) {
    return data.idVpsOrder;
  }

  private defineColumns() {
    return [
      {
        minWidth: 40, maxWidth: 40, field: 'checkbox', pinned: true,
        checkboxSelection: true, headerCheckboxSelection: true, hide: this.currentUser.isManager()
      },
      {
        headerName: 'Time', minWidth: 120, maxWidth: 120, field: 'time', pinned: true, sort: 'desc'
      },
      {
        headerName: 'Security',
        minWidth: 110,
        maxWidth: 110,
        field: 'security',
        pinned: true,
        filter: 'agTextColumnFilter',
        filterParams: {newRowsAction: 'keep'}
      },
      {
        headerName: 'Trader',
        minWidth: 150,
        maxWidth: 150,
        field: 'requestor',
        pinned: true,
        filter: 'agSetColumnFilter',
        suppressMenu: false,
        menuTabs: ['filterMenuTab'],
        filterParams: {newRowsAction: 'keep'}
      },
      {
        headerName: 'Group',
        field: 'groupId',
        minWidth: 90,
        filterParams: {newRowsAction: 'keep'},
        maxWidth: 90,
        headerTooltip: 'GroupId',
        filter: 'agSetColumnFilter',
        pinned: true,
        suppressMenu: false,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'RequestQty', minWidth: 90, maxWidth: 90, field: 'requestQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'OfferQty', minWidth: 90, maxWidth: 90, field: 'offerQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'FillQty', minWidth: 90, maxWidth: 90, field: 'fillQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      // {
      //   headerName: 'Rate', minWidth: 80, maxWidth: 80, field: 'rate', type: 'numericColumn'
      // },
      {
        headerName: 'RateOrFeeFlag', minWidth: 75, maxWidth: 75, field: 'rateOrFeeFlag',
        filter: 'agSetColumnFilter', suppressMenu: false, menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Price', minWidth: 75, maxWidth: 75, field: 'price',
        valueFormatter: CellFormatter.rate, type: 'numericColumn'
      },
      // {
      //   headerName: 'Source', field: 'idSource', valueGetter: CellFormatter.sourceStyleId, hide: false,
      //   minWidth: 80, maxWidth: 80
      // },
      {
        headerName: 'Source', field: 'idSourceText', hide: false, minWidth: 80, maxWidth: 80,
        filter: 'agSetColumnFilter', suppressMenu: false, menuTabs: ['filterMenuTab'],
      },
      // {
      //   minWidth: 70, maxWidth: 70, field: 'requestType',
      //   hide: !this.currentUser.isManager(),
      //   filter: 'agSetColumnFilter',
      //   menuTabs: ['filterMenuTab'],
      //   filterParams: {newRowsAction: 'keep'},
      //   suppressMenu: false
      // },
      {
        headerName: 'LocateId', minWidth: 80, maxWidth: 80, field: 'idVpsOrder',
        type: 'numericColumn', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'},
      },
      {headerName: 'providerLocateId', field: 'providerLocateId', hide: true},
      // {
      //   headerName: 'Status',
      //   minWidth: 120,
      //   colId: 'Status',
      //   maxWidth: 120,
      //   filter: 'agSetColumnFilter',
      //   field: 'locateStatus',
      //   filterParams: {newRowsAction: 'keep'},
      //   valueGetter: CellFormatter.statusColumnStyle,
      //   suppressMenu: false,
      //   menuTabs: ['filterMenuTab']
      // },
      {
        headerName: 'Status',
        minWidth: 120,
        colId: 'Status',
        maxWidth: 120,
        filter: 'agSetColumnFilter',
        field: 'locateStatusText',
        filterParams: {newRowsAction: 'keep'},
        suppressMenu: false,
        menuTabs: ['filterMenuTab']
      },
    ];
  }

  private defineDefaultColumns() {
    return {
      enableFilter: true,
      sortable: true,
      editable: false,
      suppressMenu: true,
      floatingFilter: true,
      resizable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      headerCheckboxSelectionFilteredOnly: true
    };
  }

}
