import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

export interface DialogData {
  groupCodes: string[];
}

@Component({
  selector: 'app-bulk-import-dialog',
  templateUrl: 'bulk-import-dialog.html',
})
export class BulkImportDialogComponent {
  private groupId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.groupCodes.length === 1) {
      this.groupId = data.groupCodes[0];
    }
  }
}
