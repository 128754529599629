import * as moment from 'moment';
import {formatDate} from '@angular/common';

export class VpsOrder {

  idVpsOrder: number;
  groupId: string;
  requestor: string;
  security: string;
  requestQty: number;
  offerQty: number;
  fillQty: number;
  rate: number;
  rateOrFeeFlag: string;
  price: number;
  locateStatus: number;
  locateStatusText: string;
  idSourceText: string;
  providerLocateId: number;
  idSource: number;
  requestType: string;
  requestTime: any;
  statusStyle: string;
  time: any;
  isSelectable: boolean;


  constructor(data: any) {
    this.idVpsOrder = data.idVpsOrder;
    this.groupId = data.groupId;
    this.requestor = data.requestor;
    this.security = data.security;
    this.requestQty = data.requestQty;
    this.offerQty = data.offerQty;
    this.fillQty = data.fillQty;
    this.rate = data.rate;
    this.rateOrFeeFlag = data.rateOrFeeFlag;
    this.price = data.price;
    this.locateStatus = data.locateStatus;
    this.providerLocateId = data.providerLocateId > -1 ? data.providerLocateId : -1;
    this.idSource = data.idSource > -1 ? data.idSource : -1;
    this.requestType = data.requestType;


    if (data.requestTime) {
      const day = data.requestTime.dayOfMonth;
      const month = data.requestTime.monthValue - 1; // Month is 0-indexed
      const year = data.requestTime.year;
      const minutes = data.requestTime.minute;
      const hours = data.requestTime.hour;
      const seconds = data.requestTime.second;
      this.time = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
      this.time = formatDate(this.time, 'MM/dd HH:mm:ss', 'en-US');
    }

    switch (data.locateStatus) {
      case -1:
        this.locateStatusText = 'New';
        break;
      case 0:
        this.locateStatusText = 'Open';
        break;
      case 1:
        this.locateStatusText = 'Partial';
        break;
      case 2:
        this.locateStatusText = 'Filled';
        break;
      case 3:
      case 5:
        this.locateStatusText = 'Pending';
        break;
      case 8:
        this.locateStatusText = 'Rejected';
        break;
      default:
        this.locateStatusText = 'Cancelled';
        break;
    }

    switch (data.locateStatus) {
      case -1:
        this.statusStyle = 'status-unprocessed';
        break;
      case 0:
        this.statusStyle = 'status-open';
        break;
      case 1:
      case 2:
        if (data.OrdType === 5) {
          this.statusStyle = 'status-overnight';
        } else {
          this.statusStyle = 'status-fill';
        }
        break;
      case 3:
      case 5:
        this.statusStyle = 'status-pend';
        break;
      case 8:
        this.statusStyle = 'status-rejected';
        break;
      default:
        this.statusStyle = 'status-cancel';
        break;
    }

    switch (data.idSource) {
      case 0:
        this.idSourceText = 'VLCY';
        break;
      case 1:
        this.idSourceText = 'GSCO';
        break;
      default:
        this.idSourceText = data.idSource;
        break;
    }

    this.isSelectable = (data.locateStatus === 5 || data.locateStatus === 0) && data.idSource === 0 ? true : false;
  }
}
