import {environment} from '../environments/environment';
import {Component} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {APP_ROUTES} from './app.routing';
import {AppComponent} from './app.component';

import {FlexLayoutModule} from '@angular/flex-layout';
import {FullComponent} from './layouts/full/full.component';
import {AppHeaderComponent} from './layouts/full/header/header.component';
import {AppSidebarComponent} from './layouts/full/sidebar/sidebar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material-module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material';
import {SharedModule} from './shared/shared.module';
import {SpinnerComponent} from './shared/spinner.component';
import {UserprofileModule} from './userprofile/userprofile.module';
import {LocatesModule} from './locates/locates.module';
import {ManagementModule} from './management/management.module';
import {AppBlankComponent} from './layouts/blank/blank.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule, AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireAuthGuard} from '@angular/fire/auth-guard';

// perfecr scrollbar
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import {
  // AngularFireModule,
  FirebaseOptionsToken
} from '@angular/fire';

import {AuthModule} from './authentication/auth.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppBlankComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatIconModule,
    FormsModule,
    FlexLayoutModule,
    // angular fire
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireModule,
    // perfect scrollbar
    PerfectScrollbarModule,
    // custom
    RouterModule.forRoot(APP_ROUTES, {useHash: true}),
    AuthModule,
    SharedModule,
    LocatesModule,
    UserprofileModule,
    ManagementModule,
    MatButtonModule,
    MatInputModule,
    HttpClientModule
  ],
  providers: [
    {provide: FirebaseOptionsToken, useValue: environment.firebase},
    AngularFireDatabase, AngularFireAuthGuard,
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}
  ],
  exports: [],
  bootstrap: [AppComponent]
})

export class AppModule {
  // private static FirebaseAppNameToken: any;
}


/*
  >>firebase init

  >>build prod
  ng build --prod --extract-css=false

  >> deploy
  firebase delpoy
*/
